<template>
  <app-scroll
    :options="{
      scrollPanel: { scrollingX: false },
    }"
  >
    <a
      class="twitter-timeline"
      :href="src"
      data-chrome="nofooter noborders noheader noscrollbar"
    />
  </app-scroll>
</template>

<script>
import AppScroll from '@core/components/app-scroll/AppScroll.vue';

export default {
  name: 'TwitterTimeline',
  components: { AppScroll },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  created() {
    const twitterFeed = document.createElement('script');
    twitterFeed.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    document.head.appendChild(twitterFeed);
  },
};
</script>
