<template>
  <list-container-widget
    id="blog-list"
    :title="widgetName || $t('twitter.timeline-title')"
    :app-id="isHome ? 68: 0"
    stretch
    class="d-flex"
  >
    <twitter-timeline v-if="computedSrc || twitterCustomizations " :src="computedSrc || twitterCustomizations" />
    <div v-else class="mt-2">
      <img :src="twitterPlaceholder" class="placeholder mx-auto">
      <p class="text-center text-primary font-weight-bold">
        {{ $t('available.message', { itemName: $t('twitter.title') }) }}
      </p>
    </div>
  </list-container-widget>
</template>

<script>
import TwitterPlaceholder from '@/assets/images/placeholders/light/twitter.svg';
import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import TwitterTimeline from '../components/TwitterTimeline.vue';

export default {
  name: 'TwitterFeedWidget',
  components: { ListContainerWidget, TwitterTimeline },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedSrc() {
      if (this.src) {
        return this.src;
      }

      const twitterLink = this.$store.getters.currentCollective.links?.find(({ label }) => label === 'Twitter');
      return twitterLink?.url;
    },
    twitterPlaceholder() {
      return TwitterPlaceholder;
    },
    isHome() {
      return this.$route.name === 'Resumen';
    },
    item() {
      return this.$store.getters.item('integrations', 'twitter-feed');
    },
    twitterCustomizations() {
      return this.item?.customizations?.Twitter_link;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        customName: 'integrations',
        page: 1,
        requestConfig: {
          orderByDate: -1,
          type: 'integrations',
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.placeholder {
  display: block;
  width: 160px;
}
</style>
